@font-face {
    font-family: 'LINESeedKR-Bd';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_11-01@1.0/LINESeedKR-Bd.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

.countdown_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* background-image: url('../../assets/box_icon.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; */
    border-radius: 10px;
}

.countdown_box>div {
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(35deg, #79CF9F, #3BB26F);
    border-radius: 10px;
}

.countdown_box>div>div {
    display: flex;
    align-items: center;
}

.countdown_box>div>div>p {
    margin-bottom: 0;
    margin-left: 5px;
}

.countdown_box p {
    font-size: 21px;
    color: #fff;
    font-family: "LINESeedKR-Bd";
}

.ended_event_wrap {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999999;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
}

.ended_event_wrap>img {
    width: 300px;
}

.ended_event_wrap>h1 {
    font-size: 35px;
    color: #fff;
    margin-top: 35px;
}

.ended_event_wrap>p {
    color: #fff;
    font-size: 14px;
}

.ended_event_wrap>button {
    margin-top: 35px;
    width: 450px;
    height: 60px;
}

.event_bottom_button_wrap {
    height: 110px;
    display: flex;
    align-items: center;
}

.event_bottom_button_wrap>button {
    width: 45px;
    height: 45px;
    border: none;
    outline: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    margin-right: 25px;
    z-index: 999;
}

.event_bottom_button_wrap>button:nth-child(1)>img {
    width: 40px;
    height: 40px;
}

.event_bottom_button_wrap>button:nth-child(2) {
    background-color: #000;
}

.event_bottom_button_wrap>button:nth-child(2)>img {
    width: 25px;
    height: 25px;
}

.event_bottom_button_wrap>button:nth-child(3) {
    background-color: transparent;
}

.event_bottom_button_wrap>button:nth-child(3)>img {
    width: 45px;
    height: 45px;
}

.email_verify_button_wrap {
    width: 100%;
    padding: 0 50px;
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
}

.email_verify_button_wrap input {
    width: 50%;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 0 15px;
}

.email_verify_button_wrap>button:not(#verify_button) {
    height: 40px;
    padding: 0 15px;
    margin-left: 15px;
}

.email_verify_button_wrap>button>p {
    margin-bottom: 0;
}

.email_verify_button_wrap>span {
    color: #fff;
    font-size: 14px;
}



@media (max-width: 1076px) {
    .countdown_box {
        width: 100%;
        height: 400px;
    }

    .countdown_box>div {
        width: 100%;
        height: 500px;
        margin-bottom: 50px;
        background-image: none;
    }

    .ended_event_wrap>img {
        width: 200px;
    }

    .event_bottom_button_wrap {
        height: 130px;
        align-items: flex-start;
    }

    .ended_event_wrap>p {
        font-size: 10px;
    }

    .ended_event_wrap>h1 {
        font-size: 20px;
        text-align: center;
    }

    .ended_event_wrap>button {
        width: 80%;
        height: 50px;
    }

    .email_verify_button_wrap>#verify_button {}

    .email_verify_button_wrap {
        padding: 0 10px;
    }

    .email_verify_button_wrap>span {
        color: #fff;
        font-size: 12px;
    }

    .email_verify_button_wrap>button>p {
        line-height: 15px;
    }

    .email_verify_button_wrap>input {
        flex: 1;
    }

    .email_verify_button_wrap>input:disabled {
        color: #fff3cd;
    }
}