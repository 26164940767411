@font-face {
    font-family: 'LINESeedKR-Bd';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_11-01@1.0/LINESeedKR-Bd.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

.container {
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main_text {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main_text>h1 {
    font-size: 40px;
    margin-bottom: 50px;
    color: #fff;
}

.container>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.roulette_container {
    width: fit-content;
    border-radius: 50%;
    animation-name: twinkling;
    /* border: 10px solid transparent; */
    background: #f2994a;
    background: -webkit-linear-gradient(to right, #f2994a, #f2c94c);
    background: linear-gradient(to right, #f2994a, #f2c94c);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    border-image: auto;
    animation-duration: 2s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    position: relative;
    animation-iteration-count: infinite;
}

@keyframes twinkling {
    0% {
        box-shadow: 0 0 10px 3px rgb(255, 255, 255, 0.4);
    }

    100% {
        box-shadow: 0 0 10px 3px rgb(255, 255, 255, 0.6);
    }
}

.light_circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 50%;
}

.bottom_box {
    max-width: 445px;
    display: flex;
    justify-content: center;
    transform: translateY(150px);
    z-index: -1;
    position: fixed;
}


.header {
    width: 20%;
    height: 80px;
    margin-bottom: 50px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
}

.header>h1 {
    margin-bottom: 0;
    color: #FFE4AA;
}