h1 {
    font-size: 100px;
}

.Snow {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    overflow: hidden;
    width: 100%;
    position: fixed;
    top: 0;
    height: 100vh;
}

.Snowflake {
    display: inline-block;
    width: 0.1%;
    color: #FFFAFA;
    opacity: 0;
    font-size: 120px;
    margin: 0;
    padding: 0;
    animation: fall 16s linear infinite;
}

@keyframes fall {
    0% {
        opacity: 0;
    }

    3% {
        opacity: 0.9;
    }

    90% {
        opacity: 0.9;
    }

    100% {
        transform: translate(0, 97vh);
        opacity: 0;
    }
}