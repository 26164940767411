@font-face {
  font-family: 'LINESeedKR-Bd';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_11-01@1.0/LINESeedKR-Bd.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}


.roulette-wheel-container * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.roulette-wheel-container {
  --spinningAnimationSpeed: 30s;
  --spinningSpeed: 9s;
  width: 374px;
  margin: 0 auto;
  user-select: none;
  font-size: 18px;
  font-weight: 400;
  font-family: Helvetica, sans-serif;
  line-height: 1.15;
}

.roulette-wheel-bet-number:nth-child(1) {
  transform: rotateZ(22.5deg);
}

.roulette-wheel-bet-number:nth-child(2) {
  transform: rotateZ(45deg);
}

.roulette-wheel-bet-number:nth-child(3) {
  transform: rotateZ(67.5deg);
}

.roulette-wheel-bet-number:nth-child(4) {
  transform: rotateZ(90deg);
}

.roulette-wheel-bet-number:nth-child(5) {
  transform: rotateZ(112.5deg);
}

.roulette-wheel-bet-number:nth-child(6) {
  transform: rotateZ(135deg);
}

.roulette-wheel-bet-number:nth-child(7) {
  transform: rotateZ(157.5deg);
}

.roulette-wheel-bet-number:nth-child(8) {
  transform: rotateZ(180deg);
}

.roulette-wheel-bet-number:nth-child(9) {
  transform: rotateZ(202.5deg);
}

.roulette-wheel-bet-number:nth-child(10) {
  transform: rotateZ(225deg);
}

.roulette-wheel-bet-number:nth-child(11) {
  transform: rotateZ(247.5deg);
}

.roulette-wheel-bet-number:nth-child(12) {
  transform: rotateZ(270deg);
}

.roulette-wheel-bet-number:nth-child(13) {
  transform: rotateZ(292.5deg);
}

.roulette-wheel-bet-number:nth-child(14) {
  transform: rotateZ(315deg);
}

.roulette-wheel-bet-number:nth-child(15) {
  transform: rotateZ(337.5deg);
}

.roulette-wheel-bet-number:nth-child(16) {
  transform: rotateZ(360deg);
}

/* Formula: (SEEMS LIKE THIS NEEDS TO BE REVISED)
const $SLICES_NUMBER = 38  // roulette-wheel-bet-number of slices (36 + 0 + 00)
const $SPINS = 8 // the roulette-wheel-bet-number of laps the ball spins before resting
const $PITWIDTH = 32 // the width of each pit
const $RIMZISE = 6 // size of the bevelled rim edge
const $SINGLE_SPIN = 9 // Math.floor(360 / 38)

const $INDEX = 0 // (this means the element position in DOM, 0 bet is not the index 0)

const $STEP_1 = -(360 * $SPINS - ($PITWIDTH * 2 + $RIMZISE) + $SINGLE_SPIN)
const $STEP_2 =  $STEP_1 + $INDEX * $SINGLE_SPIN
console.log($STEP_2)
*/
.roulette-wheel-inner[data-spinToIndex]::before {
  transition: transform 9s ease-out;
  content: '\2022';
}

.roulette-wheel-inner[data-spintoindex='0']::before {
  transform: rotateZ(-2819deg);
}

.roulette-wheel-inner[data-spintoindex='1']::before {
  transform: rotateZ(-2796.5deg);
}

.roulette-wheel-inner[data-spintoindex='2']::before {
  transform: rotateZ(-2774deg);
}

.roulette-wheel-inner[data-spintoindex='3']::before {
  transform: rotateZ(-2751.5deg);
}

.roulette-wheel-inner[data-spintoindex='4']::before {
  transform: rotateZ(-2729deg);
}

.roulette-wheel-inner[data-spintoindex='5']::before {
  transform: rotateZ(-2706.5deg);
}

.roulette-wheel-inner[data-spintoindex='6']::before {
  transform: rotateZ(-2684deg);
  /* chngd */
}

.roulette-wheel-inner[data-spintoindex='7']::before {
  transform: rotateZ(-2661.5deg);
}

.roulette-wheel-inner[data-spintoindex='8']::before {
  transform: rotateZ(-2639deg);
}

.roulette-wheel-inner[data-spintoindex='9']::before {
  transform: rotateZ(-2616.5deg);
}

.roulette-wheel-inner[data-spintoindex='10']::before {
  transform: rotateZ(-2594deg);
}

.roulette-wheel-inner[data-spintoindex='11']::before {
  transform: rotateZ(-2571.5deg);
}

.roulette-wheel-inner[data-spintoindex='12']::before {
  transform: rotateZ(-2549deg);
}

.roulette-wheel-inner[data-spintoindex='13']::before {
  transform: rotateZ(-2526.5deg);
}

.roulette-wheel-inner[data-spintoindex='14']::before {
  transform: rotateZ(-2504deg);
}

.roulette-wheel-inner[data-spintoindex='15']::before {
  transform: rotateZ(-2481.5deg);
}

.roulette-wheel-inner[data-spintoindex='1']::before {
  transform: rotateZ(-2459deg);
}

.roulette-wheel-plate {
  background-color: #808080;
  width: 350px;
  height: 350px;
  margin: 12px;
  border-radius: 50%;
  position: relative;
}

.roulette-wheel-plate.with-animation {
  animation: rotate var(--spinningAnimationSpeed) infinite linear;
}

.roulette-wheel-plate::after,
.roulette-wheel-plate::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 50%;
}

.roulette-wheel-plate::after {
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  border: 3px solid #f7e600;
}

.roulette-wheel-plate::before {
  background: rgba(0, 0, 0, 0.65);
  border: 1px solid #c0c0c0;
  box-shadow: inset 0px 0px 0px 2px #808080;
  top: 12%;
  left: 12%;
  right: 12%;
  bottom: 12%;
  z-index: 1;
}

.roulette-wheel-bet-number {
  width: 32px;
  height: 175px;
  display: inline-block;
  text-align: center;
  position: absolute;
  top: 0;
  left: calc(50% - (72px/2));
  transform-origin: 50% 100%;
  border-left: 36px solid transparent;
  border-right: 36px solid transparent;
  border-top: 175px solid #6610f2;
  box-sizing: border-box;
}

.roulette-wheel-bet-number:nth-child(odd) {
  border-top-color: #6f42c1;
}

/* 
.roulette-wheel-bet-number:nth-child(19),
.roulette-wheel-bet-number:nth-child(38) {
  border-top-color: #008000;
} */

.roulette-wheel-pit {
  color: #fff;
  padding-top: 12px;
  width: 70px;
  display: inline-block;
  font-family: 'LINESeedKR-Bd';
  font-size: 10px;
  transform: scale(1, 1.8);
  position: absolute;
  top: -175px;
  left: -36px;
}

.roulette-wheel-inner {
  display: block;
  height: 350px;
  width: 350px;
  position: relative;
}

.roulette-wheel-inner::after,
.roulette-wheel-inner::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 50%;
}

.roulette-wheel-inner::after {
  z-index: 3;
  top: 24%;
  right: 24%;
  bottom: 24%;
  left: 24%;
  background-color: #4d4d4d;
  /* border: 3px solid #808080; */
  background-image: url("../../../../assets/eml.png");
  background-position: center;
  background-size: cover;
}

.roulette-wheel-inner::before {
  top: 14%;
  bottom: 21%;
  left: 25%;
  right: 27%;
  content: "";
  color: #fff;
  font-size: 60px;
  z-index: 5;
  border-radius: 0;
  display: flex;
  /* 🚨🚨🚨 IMPORTANT 🚨🚨🚨 DO NOT REMOVE THIS 🚨🚨🚨 */
  /* The problem is that by some reasons the wheel ball worked fine while
  I was developing it. But when I tried to upload this to Codesandbox
  the roulette wheel ball turned out to be not "centered" (https://i.imgur.com/GBExbD2.png).

  Idk why I decided to to try to set `display: flex` but this worked.

  First I thought the problem might be due to different line-height
  but after playing with this property I understand it's not due to it.
  */
}

.roulette-wheel-inner.rest::before {
  transition: top 0.5s ease-in-out, right 0.5s ease-in-out, bottom 0.5s ease-in-out, left 0.5s ease-in-out;
  top: 10%;
  right: 50%;
  bottom: 20%;
  left: 25%;
}

@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}